import React from "react";
import {Outlet} from "react-router-dom";
import {ChatBubbleBottomCenterTextIcon} from "@heroicons/react/16/solid";

const AuthenticationLayout: React.FC = () => {
    return (<div className="flex min-h-screen flex-1 items-center justify-center py-12 sm:px-6 lg:px-8 flex-col px-4">
        <a href="/login">

            <div className="flex flex-row justify-center items-center">
                <ChatBubbleBottomCenterTextIcon
                    className="h-14 w-auto text-blue-500"
                    aria-hidden="true"
                />
                <h2 className="ml-4 text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Content AI
                </h2>
            </div>
        </a>
        <div className="mt-8 sm:mx-auto w-full max-w-96">
            <div className="bg-white p-8 shadow rounded-lg">
                <Outlet/>
            </div>
        </div>
        <footer className="mt-10 text-center text-gray-400 text-sm">
            © MILES Learning GmbH | {process.env.REACT_APP_VERSION}
        </footer>
    </div>);
};

export default AuthenticationLayout;
