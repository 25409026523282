import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Role, User} from "../types";
import {PlusIcon} from "@heroicons/react/16/solid";
import {getToken} from "../utils/authentication";
import {formatDate, formatDateTime} from "../utils/utils";
import {UserContext} from "../App";

const Users: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const {currentUser, setCurrentUser, currentUserToken, setCurrentUserToken} = useContext(UserContext);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "/users", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    setUsers(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    if (loading) {
        return <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>;
    }

    if (errorMessage) {
        return <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>;
    }

    return (
        <div>
            <div className="flex justify-between">
                <div>
                    <h2 className="text-lg font-semibold text-left mb-4 leading-6 text-gray-900">
                        Benutzer verwalten
                    </h2>
                    <p className="mt-1 text-sm font-medium text-left text-gray-500">
                        {currentUser?.role === Role.SuperAdmin
                            ? "Verwaltung aller Benutzer"
                            : `Verwaltung der Benutzer vom Tenant ${currentUser?.tenant?.name}`}
                    </p>
                </div>
                <Link to="/users/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600">
                        <PlusIcon className="w-4 h-4 mr-1"/>
                        Benutzer
                    </button>
                </Link>
            </div>

            <hr className="z-10 mb-8"/>

            <div className="overflow-x-auto rounded-xl">
                <table className="min-w-full divide-y divide-gray-200 bg-white border-separate rounded-xl">
                    <thead>
                    <tr>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">ID</th>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">Name</th>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">E-Mail</th>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">Tenant</th>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">Rolle</th>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">Anmeldung</th>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">Erstellt</th>
                        <th className="px-5 py-3 text-right text-xs font-medium text-gray-900 uppercase tracking-wider">Aktionen</th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{user.id}</td>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{user.first_name} {user.last_name}</td>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{user.tenant?.name}</td>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                {user.role === "super_admin" ? (<span
                                    className="rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">SuperAdmin</span>) : user.role === "tenant_admin" ? (
                                    <span
                                        className="rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700">TenantAdmin</span>) : (
                                    <span
                                        className="rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">User</span>)}
                            </td>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                {user.role !== Role.SuperAdmin && user.last_login ? (formatDateTime(user.last_login)) : "-"}</td>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{user.role !== Role.SuperAdmin ? formatDate(user.created_at) : "-"}</td>
                            {user.role !== Role.SuperAdmin &&
                                <td className="px-5 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <Link to={`/users/edit/${user.id}`}
                                          className="text-blue-600 hover:text-blue-900">bearbeiten</Link>
                                </td>
                            }
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Users;
