import {BrowserRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import MainLayout from "./components/MainLayout";
import ContentCreator from "./pages/ContentCreator";
import React, {useContext, useEffect, useState} from "react";
import "./App.css";
import {createContext} from 'react';
import KnowledgeBases from "./pages/KnowledgeBases";
import Archives from "./pages/Archives";
import Workflows from "./pages/Workflows";
import Settings from "./pages/Settings";
import WorkflowCreateEdit from "./pages/WorkflowCreateEdit";
import KnowledgeBasesCreateEdit from "./pages/KnowledgeBasesCreateEdit";
import ArchiveEdit from "./pages/ArchiveEdit";
import ContentConverter from "./pages/ContentConverter";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthenticationLayout from "./components/AuthenticationLayout";
import AdminLayout from "./components/AdminLayout";
import Tenants from "./pages/Tenants";
import TenantCreateEdit from "./pages/TenantCreateEdit";
import {Role, User} from "./types";
import Users from "./pages/Users";
import UserCreateEdit from "./pages/UserCreateEdit";
import PasswordReset from "./pages/PasswordReset";
import PasswordResetConfirm from "./pages/PasswordResetConfirm";
import Account from "./pages/Account";
import Error from "./pages/Error";
import {getToken, getUser} from "./utils/authentication";
import TenantSettings from "./pages/TenantSettings";

interface UserContextType {
    currentUser: User | undefined;
    setCurrentUser: (user: undefined) => void;
    currentUserToken: string;
    setCurrentUserToken: (token: string) => void;
}

export const UserContext = createContext<UserContextType>({
    currentUser: undefined,
    setCurrentUser: (user: User | undefined) => {
    },
    currentUserToken: '',
    setCurrentUserToken: (token: string) => ''
});

const App: React.FC = () => {
    const [currentUser, setCurrentUser] = useState<User>();
    const [currentUserToken, setCurrentUserToken] = useState<string>('');

    const getUserFromStore = async () => {
        const token = getToken();
        const user = getUser();
        if (token && user) {
            setCurrentUserToken(token);
            setCurrentUser(user);
        }
    };

    useEffect(() => {
        getUserFromStore();
    }, []);

    return (
        <BrowserRouter>
            <UserContext.Provider
                value={{
                    currentUser,
                    setCurrentUser,
                    currentUserToken,
                    setCurrentUserToken
                }}>
                <Routes>
                    <Route path="/" element={<Navigate replace to="/content-creator"/>}/>

                    <Route path="/" element={<AuthenticationLayout/>}>
                        <Route path="login" element={<Login/>}/>
                        <Route path="password-reset" element={<PasswordReset/>}/>
                        <Route path="password-reset/confirm" element={<PasswordResetConfirm/>}/>
                    </Route>

                    {/* Authenticated User Routes */}
                    <Route path="/" element={<ProtectedRoute minimumRole={Role.User}><MainLayout/></ProtectedRoute>}>
                        <Route path="content-creator" element={<ContentCreator/>}/>
                        <Route path="content-converter" element={<ContentConverter/>}/>
                        <Route path="knowledgebases" element={<KnowledgeBases/>}/>
                        <Route path="knowledgebases/create" element={<KnowledgeBasesCreateEdit action="create"/>}/>
                        <Route path="knowledgebases/edit/:id" element={<KnowledgeBasesCreateEdit action="edit"/>}/>
                        <Route path="workflows" element={<Workflows/>}/>
                        <Route path="workflows/create" element={<WorkflowCreateEdit action="create"/>}/>
                        <Route path="workflows/edit/:id" element={<WorkflowCreateEdit action="edit"/>}/>
                        <Route path="archives" element={<Archives/>}/>
                        <Route path="archives/edit/:id" element={<ArchiveEdit/>}/>
                        <Route path="Account" element={<Account/>}/>
                    </Route>

                    {/* Tenant Admin Routes */}
                    <Route path="/"
                           element={<ProtectedRoute minimumRole={Role.TenantAdmin}><AdminLayout/></ProtectedRoute>}>
                        <Route path="tenants/edit/:id" element={<TenantCreateEdit action="edit"/>}/>
                        <Route path="users" element={<Users/>}/>
                        <Route path="users/create" element={<UserCreateEdit action="create"/>}/>
                        <Route path="users/edit/:id" element={<UserCreateEdit action="edit"/>}/>
                    </Route>

                    {/* Super Admin Routes */}
                    <Route path="/"
                           element={<ProtectedRoute minimumRole={Role.SuperAdmin}><AdminLayout/></ProtectedRoute>}>
                        <Route path="settings" element={<Settings/>}/>
                        <Route path="tenants" element={<Tenants/>}/>
                        <Route path="tenants/create" element={<TenantCreateEdit action="create"/>}/>

                    </Route>

                    <Route path="*" element={<Error/>}/>
                </Routes>
            </UserContext.Provider>
        </BrowserRouter>
    );
};

export default App;
