export enum Role {
    User = "user",
    TenantAdmin = "tenant_admin",
    SuperAdmin = "super_admin",
}

export interface Settings {
    id: string;
    system_prompt: string;
}

export interface Environment {
    backend_environment: string;
    backend_version: string;
    email_cc: string;
    azure_openai_endpoint: string;
    azure_openai_deployment_llm: string;
    azure_openai_deployment_embedding: string;
}

export interface Workflow {
    id: string;
    user: User;
    tenant: Tenant;
    title: string;
    context: string;
    action: string;
    visibleForCreator: boolean;
    visibleForConverter: boolean;
    sharedWithTenant: boolean;
    isTemplate: boolean;
    isDisabled: boolean;
    created_at: string;
}

export interface KnowledgeBase {
    id: string;
    user: User;
    tenant: Tenant;
    title: string;
    description: string;
    context: string;
    sharedWithTenant: boolean;
    created_at: string;
}

export interface Document {
    id: string;
    knowledge_base_id: string;
    title: string;
    file: string;
    type: string;
    created_at: string;
}

export interface Archive {
    id: string;
    user: User;
    tenant: Tenant;
    title: string;
    history: string;
    sharedWithTenant: boolean;
    created_at: string;
}

export interface Message {
    role: string;
    content: string;
    sources: string[];
}

export interface Tenant {
    id: string;
    name: string;
    tenant_prompt: string;
}

export interface User {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    role: Role;
    tenant: Tenant;
    last_login: string | null;
    created_at: string;
}

export interface Token {
    access_token: string;
}

export interface PasswordReset {
    reset_token: string;
    new_password: string;
}

